<template>
  <div>
    <h4>LISTA DE CLIENTES</h4>
    <app-table-registers
      ref="tableRegisters"
      :getList="getList"
      @btnNew="
        $refs.formClient.reset();
        $refs.dFormClientes.show();
      "
    >
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>N° Telefono</th>
            <th>DNI</th>
            <!-- <th>Fecha de Nacimiento</th> -->
            <!-- <th>Cuentas</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.id }}</td>
            <td>{{ l.name }}</td>
            <td>
              <app-span-phonenumber
                :number="l.cell_phone_number"
              ></app-span-phonenumber>
            </td>
            <td>{{ l.idcard }}</td>
            <!-- <td>{{ l.birthdate }}</td> -->
            <!-- <td> -->
            <!--   <template v-for="k in l.accounts"> -->
            <!--     <span :key="'a' + k.money_code"> -->
            <!--       <app-span-money -->
            <!--         :quantity="k.quantity" -->
            <!--         :moneyCode="k.money_code" -->
            <!--       ></app-span-money> -->
            <!--     </span> -->
            <!--     <br :key="'b' + k.money_code" /> -->
            <!--   </template> -->
            <!-- </td> -->
            <td>
              <div class="btn-group">
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.formClient.loadReg(l);
                    $refs.dFormClientes.show();
                    $refs.elClientListSale.reload(l.id);
                  "
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.myn.show();
                    $refs.myn.val = l;
                  "
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ktr="dFormClientes" ref="dFormClientes" :lg="true">
      <h4>FORMULARIO DE CLIENTES</h4>
      <FormClient
        ref="formClient"
        @submitted="
          $refs.dFormClientes.hide();
          $refs.tableRegisters.gl();
        "
      ></FormClient>
      <legend>Lista de ventas previas</legend>
      <ClientListSale ref="elClientListSale"></ClientListSale>
    </app-modal-basic>

    <app-modal-yn
      idModal="myn"
      ref="myn"
      @yes="deleteClient($refs.myn.val.id)"
      @no="$refs.myn.hide()"
    >
      <p v-if="$refs.myn && $refs.myn.val">
        Seguro de eliminar este registro
        <strong>{{ $refs.myn.val.name }}</strong
        >?
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import FormClient from "./Form";
import { StoreService } from "../StoreService";
import ClientListSale from "./ClientListSale.vue";

export default {
  components: {
    FormClient,
    ClientListSale,
  },
  data() {
    return {
      list: {}
    };
  },
  watch: {
    $route() {}
  },
  methods: {
    deleteClient(client_id) {
      StoreService.deleteClient(client_id).then(() => {
        this.$refs.myn.hide();
        this.$refs.tableRegisters.gl();
      });
    },
    getList({ page, search }) {
      return new Promise((rsv) => {
        StoreService.getClients({
          page,
          search
        }).then((res) => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
