<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-08-14 16:43 -->
<!-- Description:  -->
<template>
  <div class="w-100">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th>FECHA</th>
          <th>ORIGEN</th>
          <th>PAGO</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="l in list" :key="l.id">
          <td>{{ l.created_at | dateTimeFormat }}</td>
          <td>{{ l.origin_slug }}</td>
          <td>
            <app-span-money :quantity="l.value"></app-span-money>
          </td>
          <td>
            <router-link
              :to="`/admin/cashregisters/${l.cash_register_id}?client_id=${l.client_id}`"
              class="btn btn-sm btn-block p-0 btn-light-outlined">
              <i class="fa fa-eye"></i>
              Ver
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
// script content
import {StoreService} from "../StoreService.js";

export default {
  name: "ClientListSale",
  components: {
    //
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: []
  }),

  computed: {
    //
  },

  // watch: {},

  async mounted() {
    //
  },

  methods: {
    async reload(clientId) {
      this.list = [];
      var res = await StoreService.getProductSales({
        client_id: clientId,
      });
      this.list = res.data;
    }
    //
    //
  }
};
</script>
<style scoped>
</style>

